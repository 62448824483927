html,
body,
#root {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    background: white;
    color: var(--black);
    margin: 0;
    font-family: 'Avenir', sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
}

/* html,
body {
	overflow-x: hidden;
} */

body.no-scroll {
    height: 100%;
    overflow: auto;
    width: 100%;
    position: fixed;
}

:root {
    --font-primary: 'Avenir', sans-serif;
    --font-secondary: 'Alternate', sans-serif;

    --ys-yellow: #ffd800;
    --ys-yellow-lt: #ffe96c;
    --ys-yellow-grad: linear-gradient(
        175deg,
        rgba(255, 234, 117, 1) 11%,
        rgba(255, 216, 0, 1) 47%
    );
    --ys-teal: #08afa4;
    --ys-grey: #5c5c5b;
    --ys-grey-lt: rgb(189, 189, 189);
    --ys-black: #070707;
    --ys-white: #fff;
    --ys-red: #ff0000;

    --ys-halfy: linear-gradient(
        90deg,
        rgba(255, 216, 0, 1) 0%,
        rgba(255, 216, 0, 1) 50%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
    );
    --ys-halfy-inv: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 216, 0, 1) 50%,
        rgba(255, 216, 0, 1) 100%
    );
    --ys-halft: linear-gradient(
        90deg,
        rgba(8, 175, 164, 1) 0%,
        rgba(8, 175, 164, 1) 50%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
    );
}

::-moz-selection,
::selection {
    background: var(--yellow);
    color: var(--black);
    opacity: 0.9;
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir'), url(./fonts/AvenirLTStd-Light.otf) format('opentype');
    font-weight: 400;
}
@font-face {
    font-family: 'Avenir';
    src: local('Avenir'), url(./fonts/AvenirLTStd-Medium.otf) format('opentype');
    font-weight: 500;
}
@font-face {
    font-family: 'Avenir';
    src: local('Avenir'), url(./fonts/AvenirLTStd-Heavy.otf) format('opentype');
    font-weight: 600;
}
@font-face {
    font-family: 'Avenir';
    src: local('Avenir'), url(./fonts/AvenirLTStd-Black.otf) format('opentype');
    font-weight: 700;
}
@font-face {
    font-family: 'Alternate';
    src: local('Alternate'),
        url(./fonts/Alternate-Gothic.otf) format('opentype');
}

h1,
h2 {
    font-family: 'Avenir', 'sans-serif';
}

h3 {
    text-transform: uppercase;
    font-family: Alternate, 'sans-serif';
    font-size: 1.85rem !important;
}

h4 {
    font-family: 'Avenir', 'sans-serif';
    font-weight: 700 !important;
    font-size: 1rem !important;
    font-style: italic;
}

h5,
h6 {
    font-family: 'Avenir', sans-serif;
    font-size: 24px;
    color: var(--black);
}

p {
    font-size: 0.8rem;
    color: var(--ys-grey);
    line-height: 1.2;
}

a:hover {
    text-decoration: none !important;
}

button:not(.ButtonBase__ButtonContainer-sc-p43e7i-3, .location, .new),
#fileUploadBtn {
    font-family: 'Avenir', sans-serif;
    padding: 0.2rem 1rem;
    transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-6deg, 0deg);
    font-weight: 600;
    max-height: 1.75rem;
    text-transform: uppercase !important;
}

button:focus,
#fileUploadBtn:focus {
    outline: none !important;
}

button.primary {
    background: var(--ys-yellow);
    border: 1px solid var(--ys-yellow);
    font-size: 0.85rem;
}

button.alternate {
    background: none;
    border: none;
    color: var(--ys-teal);
    font-size: 0.85rem;
    padding: 0;
    text-transform: capitalize !important;
    font-weight: 500;
}

button.large {
    background: var(--ys-yellow);
    border: 1px solid var(--ys-yellow);
    font-size: 1.5rem;
    font-weight: 500;
    width: 100%;
    padding: 0.5rem 0 2.75rem 0;
    max-height: 2.5rem !important;
}

@media (max-width: 768px) {
    button.large {
        font-size: 1rem;
        font-weight: 600;
        padding: 0.5rem;
    }
}

button.primary:hover,
button.large:hover {
    background: var(--ys-yellow-lt);
    transition: 0.3s;
}

/* button.primary:focus {
  outline:none!important;
} */

button.secondary,
#fileUploadBtn {
    background: #d7d7d7;
    border: 1px solid #d7d7d7;
    font-size: 0.85rem;
}

button.secondary:hover,
#fileUploadBtn:hover {
    background: #f0f0f0;
    transition: 0.3s;
}

a {
    text-decoration: none;
    color: var(--ys-black) !important;
}

.pt2rem {
    padding-top: 2rem !important;
}

/* Seperator */

/* .SeperatorText {
  font-family: 'Avenir', sans-serif;
  font-style:italic;
  font-size:.85rem;
  display: inline-block;
  padding: .4rem 1.25rem;
  text-transform: uppercase;
  clip-path: polygon(7% 0,100% 0%,93% 101%,0% 101%);
}

.SeperatorLine {
  height: .1rem;
  width: 495px;
  display: inline-block;
} */

/* Home slider */

.sliderImgAdjust {
    float: right;
    width: 100%;
}

/* Common slider settings */

.slick-dots li.slick-active button:before {
    color: var(--ys-teal);
}

.slick-dots li button:before {
    font-size: 10px;
}

.slick-dots li {
    margin: 0 3px;
}

/* Menu properties */

.active-menu,
.active-menu-temp {
    background: var(--ys-teal) !important;
    color: white;
}

span.MegaMenu {
    pointer-events: none;
}

@media (max-width: 768px) {
    .page-banner-a-image {
        display: none;
    }
}

.terms {
    font-size: 0.8rem;
    color: var(--ys-grey);
    line-height: 1.2;
}

ol.terms {
    counter-reset: item;
    padding: 1rem;
}
li.terms {
    display: block;
    padding: 0.75rem 0 0 0;
}
li.terms:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
}
