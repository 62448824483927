#testimonials-slider .slick-slide {
    transition:.5s;
    opacity:0;
}

#testimonials-slider .slick-slide.slick-active {
    opacity:.4!important;
    clip-path: polygon(7% 0,100% 0%,93% 101%,0% 101%);
}

#testimonials-slider .slick-slide.slick-active.slick-center.slick-current {
    background:var(--ys-yellow);
    background:var(--ys-yellow-grad);
    opacity:1!important;
    transition:.5s;
    clip-path: polygon(7% 0,100% 0%,93% 101%,0% 101%);
}

#testimonials-slider .slick-slide .faQuote {
    transition:.5s;
    opacity:0;
}

#testimonials-slider .slick-slide.slick-active .faQuote {
    opacity:0;
}

#testimonials-slider .slick-slide.slick-active.slick-center.slick-current .faQuote {
    opacity:1;
}

#testimonials-slider .slick-slide.slick-active.slick-center.slick-current p.topBorder, 
#testimonials-slider .slick-slide.slick-active.slick-center.slick-current h4.bottomBorder {
    border-color:transparent;
}

p.topBorder {
    border-top: 2px solid var(--ys-grey);
    transition:.5s;
    /* transition-delay:.25s; */
    /* transition-timing-function: cubic-bezier(0,0,0,0.1); */
}

h4.bottomBorder {
    border-bottom: 2px solid var(--ys-grey);
    transition:.5s;
    /* transition-timing-function: cubic-bezier(1,1,1,1); */
}

#testimonials-slider .slick-list {
    padding-bottom: 1rem!important;
}