#heroSlider .slick-dots {
  bottom: -2.5rem;
  text-align: center;
  z-index: 5;
  width: 100vw;
  left: -40vw;
  position: absolute;
}

#heroSlider .slick-dots li button::before {
  color: var(--ys-yellow);
  opacity: 1;
}

#heroSlider .slick-dots li.slick-active button::before {
  color: var(--ys-teal);
}
